import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../../components/layout"
import Markdown from "react-markdown"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import { parse, format } from "date-fns"

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: { eq: "published" }) {
      strapiId
      title
      description
      content
      content_images {
        childImageSharp {
          original {
            src
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        base
      }
      publishedAt(formatString: "DD MMMM YYYY")
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      author {
        name
        email
        picture {
          childImageSharp {
            fixed(width: 120, height: 120) {
              src
            }
          }
        }
      }
    }
  }
`

const Article = ({ data, location }) => {
  const article = data.strapiArticle

  if (!article)
    return (
      <Layout>
        <div>Not found</div>
      </Layout>
    )

  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
    url: location.pathname,
    publishedAt: parse(article.publishedAt, "dd MMMM yyyy", new Date()),
    authorName:
      article.author && article.author.name ? article.author.name : "",
    articleBody: article.content,
  }

  return (
    <Layout seo={seo}>
      <article className="container lg:mt-12 mx-auto px-4 sm:px-12 mb-12 max-w-screen-lg bg-gray-50 md:py-8 overflow-x-hidden">
        <BackgroundImage
          fluid={article.image.childImageSharp.fluid}
          className="h-64 lg:h-80 -mt-8 -mx-12 mb-8"
        ></BackgroundImage>

        <h1 className="text-3xl font-bold mb-4 border-b-4 pb-4 border-green-500">
          {article.title}
        </h1>

        <Markdown
          source={article.content}
          className="markdown"
          escapeHtml={false}
          renderers={{
            image: ({ src, alt }) => {
              if (article.content_images) {
                const image = article.content_images.find(
                  element => element.base === src
                )
                if (image.childImageSharp) {
                  return <Img fluid={image.childImageSharp.fluid} alt={alt} />
                } else {
                  return <div></div>
                }
              }
            },
          }}
        />

        <hr className="my-8" />

        {!!article.author && !!article.author.picture && (
          <Img
            fixed={article.author.picture.childImageSharp.fixed}
            imgStyle={{ position: "static", borderRadius: "50%" }}
            alt={article.author.name}
          />
        )}

        {!!article.author && !!article.author.name && (
          <p>
            <address className="font-bold text-green-500 not-italic">
              <Link rel="author" to="/aboutus">
                {article.author.name}
              </Link>
              {!!article.author && !!article.author.email && (
                <a
                  className="text-black block font-normal	"
                  href={`mailto:${article.author.email}`}
                >
                  {article.author.email}
                </a>
              )}
            </address>
          </p>
        )}
      </article>
    </Layout>
  )
}

export default Article
